@import 'form-errors';
@import 'form-inputs';

form {
    // title group
    h3 {
        align-items: center;
        display: flex;
        font-size: 25px;
        font-weight: bold;
        line-height: 30px;
        margin: 30px 0;

        &.dm-serif {
            display: block;
        }

        &::before {
            background: $primary;
            content: '';
            height: 2px;
            margin-right: 8px;
            margin-top: 2px;
            width: 30px;
        }
    }
}
