.job-list-filters {
    min-height: 30px;
    position: relative;
    &__wrapper {
        background: #f8f9fa;
    }
    button[type='reset'] {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        background: transparent;
        border: 0;
        opacity: 0.8;
        transition: all 0.2s;
        &:hover {
            opacity: 1;
        }
    }
    input[gmaps-autocomplete] {
        border: none;
        background: #f8f9fa;
        color: #354b7b;
        height: 100%;

        &::placeholder {
            color: #5d5d5d;
            opacity: 1;
        }
        &:focus-visible {
            outline: initial;
        }
    }
    .container {
        & > .row {
            & > [class*='col'] {
                &:not(:last-of-type) {
                    .job-list__filter {
                        & > label {
                            border-right: 1px solid #e9eaeb;
                        }
                    }
                }
            }
        }
    }
    .job-list__filter__trigger {
        display: none;
        & + .job-list__filter {
            position: relative;
            & > label {
                height: 60px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                padding: 0 30px;
                transition: all 0.2s;
                cursor: pointer;
                &:after {
                    content: '';
                    height: 7px;
                    width: 7px;
                    display: inline-block;
                    border-bottom: 1px solid $grey;
                    border-left: 1px solid $grey;
                    transform: rotate(-45deg);
                    margin-left: auto;
                    margin-bottom: 2px;
                }
                i {
                    height: 20px;
                    width: 20px;
                    flex-shrink: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    font-size: 10px;
                    font-weight: bold;
                    font-style: initial;
                    background: $tertiary;
                    color: white;
                    margin: 0 10px;
                    box-shadow: 0 0 0 5px rgba($tertiary, 0.25);
                }
            }
            .links-list {
                position: absolute;
                top: 100%;
                padding: 5px 0;
                background: white;
                box-shadow: 0 0 30px rgba(black, 0.15);
                min-width: calc(100% - 1px);
                z-index: 1000;
                max-height: 310px;
                overflow: auto;
                opacity: 0;
                transform: translateY(10px);
                pointer-events: none;
                visibility: hidden;
                transition: all 0.2s;
                input[type='checkbox'],
                input[type='radio'] {
                    display: none;
                    &:checked + label {
                        &:before {
                            background: $primary;
                            border-color: $primary;
                        }
                    }
                    & + label {
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        padding: 10px 20px 10px 15px;
                        transition: all 0.2s;
                        color: $grey;
                        white-space: nowrap;
                        cursor: pointer;
                        img {
                            height: 25px;
                            width: 25px;
                            border-radius: 100%;
                            margin-right: 10px;
                        }
                        &:before {
                            content: '';
                            height: 13px;
                            width: 13px;
                            border: 1px solid $grey;
                            margin: 0 13px 0 0;
                            border-radius: 2px;
                            flex-shrink: 0;
                        }
                        &:hover {
                            background: #fafafa;
                        }
                    }
                }
            }
            .more-filters {
                position: absolute;
                top: 100%;
                right: 0;
                min-width: 100%;
                z-index: 1000;
                background: white;
                box-shadow: 0 0 30px rgba(black, 0.15);
                opacity: 0;
                transform: translateY(10px);
                pointer-events: none;
                visibility: hidden;
                transition: all 0.2s;
                .main {
                    padding: 40px 50px 10px;
                    min-width: 500px;
                    label {
                        white-space: nowrap;
                        font-weight: bold;
                        font-size: 15px;
                        margin-bottom: 10px;
                    }
                    input,
                    select {
                        height: 28px;
                        width: 100%;
                        border-radius: 5px;
                        border: 1px solid $grey;
                        font-size: 14px;
                        padding: 0 7px 2px;
                        margin-bottom: 30px;
                        background: white;
                    }
                }
                .footer {
                    padding: 15px 20px;
                    border-top: 1px solid rgba(#5d5d5d, 0.1);
                }
            }
        }
        &:checked {
            & + .job-list__filter {
                & > label {
                    background: white;
                    box-shadow: 0 0 30px rgba(black, 0.15);
                }
                .links-list,
                .more-filters {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: initial;
                    visibility: visible;
                }
            }
        }
    }
    &__suggestions,
    &__register {
        margin-top: 10px;
        ul {
            white-space: nowrap;
            overflow: auto;
            padding: 15px 0;
        }
        a {
            padding: 5px 10px;
            border-radius: 20px;
            display: inline-block;
            border: 1px solid #eee;
            font-size: 13px;
            color: #999;
            font-weight: 600;
            transition: all 0.2s;
            &:hover {
                border-color: $primary;
                color: $primary;
            }
            &.active {
                background: $primary;
                border-color: $primary;
                color: white;
            }
        }
    }

    @include md-down {
        max-width: 100%;
        .container {
            max-width: 100%;
        }
        .job-list__filter__trigger {
            & + .job-list__filter {
                & > label {
                    height: 50px;
                    padding: 0 20px;
                }
            }
        }
    }

    @include sm-down {
        .job-list__filter__trigger {
            & + .job-list__filter {
                & > label {
                    font-size: 14px;
                }
            }
        }
    }
}

.job-list-filters__flags {
    padding: 13px 17px;
    background: #f8f9fa;
    white-space: nowrap;
    overflow: auto;
    a {
        opacity: 0.5;
        transition: all 0.2s;
        position: relative;
        display: inline-block;
        &.active {
            opacity: 1;
        }
        i {
            position: absolute;
            top: 0;
            right: -2px;
            padding-bottom: 2px;
            height: 15px;
            width: 15px;
            background: $primary;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #f8f9fa;
            &:before {
                content: '';
                height: 4px;
                width: 7px;
                display: inline-block;
                border-left: 2px solid white;
                border-bottom: 2px solid white;
                transform: rotate(-45deg);
            }
        }
        img {
            height: 35px;
            width: 35px;
            border-radius: 100%;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

.job-list-filters__trigger {
    padding-left: 20px;
    padding-right: 20px;
    img {
        margin-right: 15px;
    }
}

#job-list-filters__toggle {
    display: none;
    &:checked {
        & + section {
            transform: translateY(0);
            opacity: 1;
            pointer-events: initial;
            visibility: visible;
        }
    }
}

.job-list-filters__mobile {
    position: fixed;
    top: 60px;
    height: calc(100vh - 60px);
    width: 100%;
    z-index: 100;
    background: #f8f9fa;
    opacity: 0;
    transform: translateY(10px);
    display: flex;
    flex-direction: column;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.2s;
    overflow: scroll;
    padding: 60px 0 80px;
    header {
        width: 100%;
        padding: 0 15px;
        border-bottom: 1px solid rgba($grey, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        .row {
            height: 60px;
            display: flex;
            align-items: center;
        }
        .icon {
            margin-right: 15px;
        }
        label {
            margin: 0;
            padding: 10px;
            margin-right: -10px;
        }
    }
    form {
        height: 100%;
    }
    main {
        padding: 20px 15px 0;
        height: calc(100% - 60px - 80px);
        overflow: auto;
        height: 100%;
        label {
            display: block;
            font-weight: bold;
            font-size: 15px;
            margin-bottom: 10px;
        }
        input[type='text'] {
            height: 30px;
            width: 100%;
            border: 1px solid $grey;
            border-radius: 3px;
            background: transparent;
            outline: none;
            padding: 0 10px 2px 7px;
            font-size: 14px;
            margin-bottom: 20px;
        }
        input[gmaps-autocomplete] {
            border: none;
            color: #354b7b;
            margin-bottom: 0;

            &::placeholder {
                color: #5d5d5d;
                opacity: 1;
            }
            &:focus-visible {
                outline: initial;
            }
        }
        .job-list-filters__flags {
            margin: 0 -15px;
            width: calc(100% + 30px);
            padding: 0 15px;
            margin-top: 15px;
            margin-bottom: 20px;
        }
        .section-toggle {
            display: none;
            &:checked + label {
                &:after {
                    transform: rotate(-45deg);
                }
                & + section {
                    max-height: 1000px;
                    padding-bottom: 15px;
                }
            }
            & + label {
                height: 50px;
                width: calc(100% + 30px);
                display: flex;
                align-items: center;
                border-top: 1px solid rgba($grey, 0.1);
                margin-left: -15px;
                padding: 0 15px;
                margin-bottom: 0;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-left: 1px solid $grey;
                    border-bottom: 1px solid $grey;
                    margin: 0 0 0 auto;
                    transform: rotate(-135deg);
                }
                & + section {
                    overflow: hidden;
                    max-height: 0;
                    input[type='checkbox'] {
                        display: none;
                        &:checked + label {
                            i {
                                background: $primary;
                                border-color: $primary;
                            }
                        }
                        & + label {
                            display: flex;
                            align-items: center;
                            padding: 5px 0;
                            margin-bottom: 0;
                            i {
                                display: inline-block;
                                height: 10px;
                                width: 10px;
                                border: 1px solid $grey;
                                margin-right: 12px;
                                flex-shrink: 0;
                                transition: all 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        border-top: 1px solid $grey;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .row {
            height: 80px;
        }
    }
}
