.notie-textbox {
    font-size: 1.1rem;
    z-index: 9 !important;
    transform: translateY(80px);

    @include xs-only {
        transform: translateY(60px);
    }
}

// do not
body {
    &.security-login {
        .notie-textbox {
            transform: initial;
        }
    }
}
