.section-heading {
    color: $grey;
    font-size: 35px;
    text-align: center;
    line-height: 35px;
    margin-bottom: 80px;
    p {
        color: $primary;
        display: inline;
    }
}
.section-heading-small {
    color: $primary;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}
.section-heading {
    color: $grey;
    font-size: 35px;
    text-align: center;
    margin-bottom: 80px;
}

.link-primary {
    background: $primary;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 5px 1px;
    color: white;
    font-weight: bolder;
    box-shadow: 0px 0px 0px 8px rgba($primary, 0.25);
    transition: all 0.2s;
    &:hover {
        box-shadow: 0 0 0 11px rgba($primary, 0.25);
        color: white;
    }
}

.mb-bottom {
    font-size: 12px;
    color: $grey;
    line-height: 22px;
}

.mb-table{
    border-radius:10px; overflow:hidden; border:1px solid $tertiary;
    .table{
        margin-bottom:0;
        th, td{padding:15px;}
    }
    .thead-dark{
        th{background:$tertiary; border-color:$tertiary;}
    }
    .table-success{
        background:white;
        th, td{background-color:rgba($tertiary, .2); border-color:rgba($tertiary, .2);}
    }

    small {
        background: #ed3740;
        padding: 5px;
        color: #fff;
        font-weight: 500;
        border-radius: 4px;
    }
}

.mb__not-find-match {
    margin-top: 70px;
    article {
        border-radius: 50px;
        background-color: $tertiary;
        position: relative;
        p {
            font-size: 1rem;
            line-height: 24px;
            color: white;
        }
    }
    figure {
        border-radius: 45px 0 0 45px;
        height: 100%;
        min-height: 200px;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
    .link-primary {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
    }
    h3 {
        font-size: 35px;
        color: white;
        line-height: 35px;
        margin-bottom: 20px;
    }
    .content {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
        padding-right: 80px !important;
    }
    .mb-bottom {
        margin-top: 90px;
    }

    @include sm-down {
        figure {
            border-radius: 45px 45px 0 0;
        }
        .link-primary {
            right: 50%;
            bottom: 0;
            top: auto;
            transform: translate(50%, 50%);
            z-index: 10;
        }
        .content {
            padding: 30px 50px 60px !important;
        }
        h3 {
            font-size: 25px;
            line-height: 25px;
            margin-bottom: 10px;
        }
        article {
            text-align: center;
            p {
                font-size: 14px;
            }
        }
        .mb-bottom {
            margin-top: 50px;
        }
        button {
            height: 35px;
            width: 35px;
        }
    }

    @include xs-only {
        margin-bottom: 90px;
        figure {
            min-height: 160px;
        }
    }
}
