// Overrides for Bootstrap modals
.modal-body {
    min-height: 300px;
}

.modal-content {
    border: 0;
    border-radius: 0;
    box-shadow: 20px #0000001a!important;
}
