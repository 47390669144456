body.order-index,
body.home-recruitment {
    .details {
        border: 4px solid #eee;
        padding: 20px;

        ul {
            list-style: none;
            li {
                padding-bottom: 10px;
            }
        }
    }

    .final-price {
        display: block;
        font-size: 2rem;
        font-weight: 600;
        color: #ed3740;
        padding: 20px 0;
    }

    .coupon {
        a {
            position: absolute;
            bottom: 30%;
            color: #41507b;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}
