$primary: #ed3740;
$secondary: #ffc772;
$tertiary: #354b7b;

$grey: #5d5d5d;
$blue: #344b7d;

$facebook: #3b5998;
$linkedin: #007bb5;

$notie-color-success: #57BF57;
$notie-color-warning: $blue;
$notie-color-error: $primary;
$notie-color-info: $tertiary;
$notie-color-neutral: #F8F9FA;

$notie-font-size-mobile: .8rem;
$notie-font-size-tablet: .8rem;
$notie-font-size-desktop: .9rem;
$notie-font-size-widescreen: 1rem!important;
