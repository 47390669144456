.main-footer{
    background: $primary; margin-top: 130px; position:relative; padding-top:30px;
    .wave{position:absolute; bottom:calc(100% - 5px); left:-10px; width:calc(100% + 20px);}
    .list-group-item {
      background-color: transparent; border: none; color: white; font-size: 14px; padding:0;
      &:not(:last-child){margin-bottom:10px;}
      &.list-icon{margin-bottom:20px;}
      &.list-title {
        font-weight: bold; margin-bottom:20px;
        h2, h3{font-size:14px; font-weight:bold;}
      }
    }
    a{
      color:white; transition:all .2s;
      &:hover{opacity:.8;}
    }
    .links{margin-top:25px;}
    .socials {
      text-align: center; margin-top:10px;
      p {font-size: 14px; font-weight: bold; line-height: 26px; color: white;}
      a { img {margin: 15px 5px;} }
    }
    hr{ border-top: 1px solid rgba(255, 255, 255, 0.47); }
    .copyright {text-align: center; padding-top: 10px; padding-bottom: 20px; color: white; font-size: 12px; line-height: 26px;}

    @include sm-down{
      margin-top:80px;
    }

    @include xs-only{
      margin-top:50px;
    }
  }