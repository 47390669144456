.sub-nav{
  min-height:50px; width:100%; background:#F4F4F4; display:flex; align-items:center; padding:0 15px 11px;
  ul{display:flex; align-items:center; flex-wrap:wrap;}
  li{
    margin-right:0 !important;
    a{
      color:#5D5D5D; transition:all .2s; padding:16px 11px 5px; display:inline-block; 
      &:hover{color:#333;}
      &.active{color:$primary;}
    }
  }
}