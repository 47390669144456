.jobs-slider {
    position: relative;

    .link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
    a {
        &.button-primary {
            z-index: 2;
        }
    }
    .slick-list {
        overflow: visible;
        position: relative;
    }
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        padding: 0 7.5px;
        height: inherit !important;
        & > div,
        article {
            height: 100%;
        }
        article {
            transition: all 0.5s;
        }
        &:not(.slick-active) article {
            opacity: 0.2;
            pointer-events: none;
        }
    }
    button {
        height: 45px;
        width: 45px;
        border-radius: 100%;
        background: $primary;
        box-shadow: 0 0 0 8px rgba($primary, 0.2);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        z-index: 100;
        transition: all 0.2s;
        &:hover {
            box-shadow: 0 0 0 10px rgba($primary, 0.2);
        }
        &.prev {
            right: 100%;
            margin-right: 25px;
        }
        &.next {
            left: 100%;
            margin-left: 25px;
            padding-left: 3px;
            img {
                transform: rotate(180deg);
            }
        }
    }
    article {
        background-color: #f8f9fa;
        border-radius: 50px;
        overflow: hidden;
        border: 1px solid #ebedf2;
        display: flex !important;
        flex-direction: column;
        header {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 30px;
            height: 125px;
            background-color: white;
            img {
                max-width: 60%;
                max-height: 80%;
            }
        }
        main {
            position: relative;
            padding: 25px 25px 35px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
        .flag {
            position: absolute;
            right: 20px;
            top: 0;
            transform: translateY(-50%);
            height: 42px;
            width: 42px;
            border-radius: 100%;
        }
        h3 {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 5px;
            color: $primary;
            font-weight: 600;
            transition: all 0.2s;
            &:hover {
                opacity: 0.7;
            }
        }
        p {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
            margin-top: 15px;
        }
        small {
            font-size: 12px;
            line-height: 26px;
            font-style: italic;
            margin-bottom: 20px;
            display: inline-block;
            margin-top: 10px;
        }
        .button-primary {
            padding-left: 30px;
            padding-right: 30px;
            margin: auto auto 0;
        }
    }

    @include xs-only {
        padding: 0 35px;
        button {
            height: 35px;
            width: 35px;
            &.prev {
                margin-right: -15px;
            }
            &.next {
                margin-left: -15px;
            }
        }
    }
}
