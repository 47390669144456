.badge {
    border-radius: 10px;
    padding: 5px 10px;

    &.badge-info {
        background-color: $tertiary;
    }

    &.badge-warning {
        background-color: $secondary;
    }

    &.badge-danger {
        background-color: $primary;
    }
}
