body.congratulation {

    &.congratulation-purchasedJob {
        .loader-big {
            position: fixed;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: #ffffff;

            img {
                margin-top: 18%;
            }

            .loader-message {
                padding: 10px;
                color: $primary;
            }
        }
    }
}
