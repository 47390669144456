// Google Place autocomplete

.pac-container {
    border-radius: 2px;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #f0f0f1;
    font-family: 'Lato';
    font-size: 15px;
}
.pac-item {
    border: none;
    padding: 5px;

    // On Firefox Mobile (at least on emulator), one cannot click on result item
    // By specifying the margin, this works...
    // https://stackoverflow.com/questions/29174957/google-places-autocomplete-plugin-isnt-working-in-firefox-android
    &:first-child {
        margin-top: 10px;
    }

    &:hover {
        background-color: #ffc772a8;
    }
}

.pac-item-selected {
    background-color: #ffc772a8;
}
