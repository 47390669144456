@charset "UTF-8";
article,
aside,
audio,
canvas,
command,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video {
    display: block;
}
mark,
rp,
rt,
ruby,
summary,
time {
    display: inline;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0;
    font-weight: normal;
    line-height: 1.1;
}
ul,
label,
figure {
    margin-bottom: 0;
}
p {
    margin-bottom: 0;
}
strong {
    font-weight: 600;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
button {
    outline: none !important;

    &:disabled {
        background: #eee;
    }
}
a:hover {
    text-decoration: none !important;
    outline: 0 !important;
}
a:focus {
    text-decoration: none !important;
}

img {
    -webkit-user-drag: none;
}

html {
    overflow-x: hidden;
}

@include sm-down {
    body {
        overflow-x: hidden;
    }
}

html,
body {
    &.noScroll {
        overflow: hidden;
    }
}

.dm-serif {
    font-family: 'DM Serif Display', serif;
}

body {
    font-family: 'Lato';
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $grey;
    &.base {
        padding-top: 80px;
    }
    @media (max-width: 890px) {
        &.base {
            padding-top: 80px;
        }
    }
    @include xs-only {
        &.base {
            padding-top: 60px;
        }
    }
}

//////////////////////////
/////     OTHERS     /////
//////////////////////////
.full-height {
    height: 100% !important;
}
.full-width {
    width: 100% !important;
}

//////////////////////////
///// COLUMNS GUTTER /////
//////////////////////////
.row {
    &.row-80 {
        margin: 0 -40px;
        & > [class*='col'] {
            padding: 0 40px;
        }
    }
    &.row-60 {
        margin: 0 -30px;
        & > [class*='col'] {
            padding: 0 30px;
        }
    }
    &.row-50 {
        margin: 0 -25px;
        & > [class*='col'] {
            padding: 0 25px;
        }
    }
    &.row-40 {
        margin: 0 -20px;
        & > [class*='col'] {
            padding: 0 20px;
        }
    }
    &.row-30 {
        margin: 0 -15px;
        & > [class*='col'] {
            padding: 0 15px;
        }
    }
    &.row-20 {
        margin: 0 -10px;
        & > [class*='col'] {
            padding: 0 10px;
        }
    }
    &.row-15 {
        margin: 0 -7.5px;
        & > [class*='col'] {
            padding: 0 7.5px;
        }
    }
    &.row-10 {
        margin: 0 -5px;
        & > [class*='col'] {
            padding: 0 5px;
        }
    }
    &.row-0 {
        margin: 0;
        & > [class*='col'] {
            padding: 0;
        }
    }
}

.wrapword {
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /*Chrome & Safari */
    white-space: -pre-wrap;        /* Opera 4-6 */
    white-space: -o-pre-wrap;      /* Opera 7 */
    white-space: pre-wrap;         /* CSS3 */
    word-wrap: break-word;         /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
}
