body.blog-index {
    background-image: url('../images/blog-bg-shape.svg');
    background-position: right top 70px;
    background-repeat: no-repeat;
    .main-nav {
        background: white;
        box-shadow: 0 0 20px rgba(black, 0.1);
        background: white;
    }
    @include md-down {
        background-image: none;
    }
}

body.blog-details {
    .main-nav {
        background: white;
    }
}

.blog {
    &__title {
        color: $tertiary;
        font-size: 25px;
        font-weight: bold;
        margin-top: 60px;
    }
    &__subtitle {
        color: $tertiary;
        font-size: 16px;
        line-height: 22px;
        margin-top: 5px;
    }
    &__categories {
        margin: 40px -30px 0 0;
        width: calc(100% + 30px);
        li {
            margin-bottom: 10px;
            margin-right: 10px;
        }
        a {
            border: 1px solid $primary;
            border-radius: 40px;
            color: $primary;
            display: inline-block;
            font-size: 15px;
            font-weight: bold;
            padding: 10px 20px;
            transition: all .2s;
            &:hover {
                background: rgba($primary, .05);
            }
            &.active {
                background: $primary;
                color: white;
            }
        }
    }

    &__post-list {
        margin-top: 35px;
    }

    &-post {
        border-radius: 10px;
        background: white;
        box-shadow: 0 0 0 1px #eee;
        overflow: hidden;
        transition: all 0.2s;
        color: $grey;
        height: 100%;
        display: flex;
        flex-direction: column;
        &__wrapper {
            margin-bottom: 35px;
        }
        &:hover {
            box-shadow: 0 4px 15px rgba(black, .05);
            transform: translateY(-5px) scale(1.01);
        }
        img {
            background-color: #f4f4f4;
            background-position: center;
            background-size: cover;
            height: auto;
        }
        .content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 30px 35px;
        }
        .infos {
            color: $tertiary;
            font-size: 11px;
        }
        .category {
            color: $primary;
        }
        h3 {
            color: $tertiary;
            font-size: 25px;
            font-weight: bold;
            line-height: 28px;
            margin-top: 20px;
        }
        p {
            font-size: 15px;
            line-height: 22px;
            margin: 10px 0 25px;
        }
        &__content {
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                width: 100%;
            }
        }

        .read {
            align-items: center;
            color: $tertiary;
            display: flex;
            font-size: 14px;
            font-weight: bold;

            img {
                height: 10px;
                margin-left: 10px;
            }
        }
        &__social-sharing {
            position: sticky;
            top: 120px;

            &__wrapper {
                position: relative;
            }

            & > div {
                display: flex;
                justify-content: center;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            a {
                display: inline-block;
                transition: all .2s;
                &:hover {
                    opacity: .7;
                }
            }
        }
        &__progress {
            background: $primary;
            height: 3px;
            left: 0;
            position: fixed;
            top: 80px;
            width: 0;
            z-index: 1000;
        }
    }

    &__sidebar-block {
        background: #f8f9fb;
        border-radius: 10px;
        box-shadow: 0 0 0 1px #eee inset;
        margin-top: 30px;
        padding: 30px 40px;

        h4 {
            color: $tertiary;
            font-size: 21px;
            font-weight: bold;
        }
        p {
            font-size: 15px;
            line-height: 18px;
            margin-top: 15px;

            span {
                color: $tertiary;
            }
        }
    }

    &__newsletter {
        background: $tertiary;
        box-shadow: 0 0 0;
        input {
            border-radius: 40px;
            text-align: center;

            &:focus {
                box-shadow: 0 0 0 6px rgba(white, .2), 0 0 0 1px rgba(white, .3);
            }
        }
        h4,
        p {
            color: white;
        }
    }

    // @include lg-up {
    //     &-post {
    //         &__content {
    //             iframe {
    //                 transform: scale(.9);
    //             }
    //         }
    //     }
    // }

    @include md-down {
        &__categories {
            margin: 30px 0 0;
            width: 100%;
        }
        &-post {
            &__social-sharing {
                justify-content: flex-start;
                & > div {
                    justify-content: flex-start;
                }
                img {
                    width: 130%;
                }
            }
        }
    }

    @include sm-down {
        &-post {
            &__content {
                margin-top: 40px !important;

                iframe[src*='youtube'] {
                    height: 350px;
                }
            }

            &__social-sharing {
                img {
                    width: 40px;
                }
                a {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    @include xs-only {
        &__title {
            margin-top: 30px;
        }
        &-post {
            &__content {
                margin-top: 15px !important;

                iframe[src*='youtube'] {
                    height: 250px;
                }
            }

            .content {
                padding: 20px 25px;
            }

            h3 {
                font-size: 20px;
                line-height: 23px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
            }

            &__progress {
                top: 60px;
            }
        }
    }
}
