.main-nav {
    align-items: center;
    background-color: white;
    display: flex;
    height: 80px;
    left: 0;
    position: fixed;
    top: 0;
    transition: box-shadow .2s;
    width: 100%;
    z-index: 10;

    &.scrolled {
        background: white;
        box-shadow: 0 0 20px rgba(black, 0.1);
        z-index: 1000;
    }

    .container-fluid {
        padding: 0 25px;
    }

    #main-nav__mobile-toggle {
        display: none;
    }

    &__menu-toggle {
        display: none;

        label {
            cursor: pointer;
            height: 20px;
            margin-bottom: 0;
            position: relative;
            transition: all .3s;
            width: 25px;

            span {
                background: $primary;
                border-radius: 4px;
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                top: 0;
                transition: all .3s;
                width: 100%;

                &:nth-of-type(2) {
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:nth-of-type(3) {
                    bottom: 0;
                    top: auto;
                }
            }
        }
    }

    &__logo {
        a {
            align-items: center;
            display: flex;
            transition: all .2s;

            &:hover {
                opacity: .7;
            }

            span {
                color: $tertiary;
                font-size: 18px;
                font-weight: bold;
                margin-left: 20px;
            }
        }
    }

    ul {
        li {
            margin: 5px 8px 5px 0;
        }

        a:not(.button-primary):not(.button-tertiary):not(.button-primary-outline):not(.button-tertiary-outline) {
            color: $grey;
            font-size: 15px;
            font-weight: 500;
            padding: 6px;

            &:hover,
            &.active,
            &.underline {
                color: $primary;
                transition: all .2s;
            }

            &.active,
            &.underline {
                position: relative;

                &:after {
                    background: $primary;
                    content: '';
                    height: 1px;
                    left: 6px;
                    margin-top: -5px;
                    position: absolute;
                    top: 100%;
                    width: calc(100% - 12px);
                }
            }

            &.recruit {
                color: $tertiary;

                &:hover {
                    opacity: .7;
                }

                &:after {
                    background: $tertiary;
                }
            }

            &.candidate {
                color: $primary;

                &:hover {
                    opacity: .7;
                }

                &:after {
                    background: $primary;
                }
            }
        }
    }

    &__language {
        margin-left: 15px;
        position: relative;

        i {
            border-bottom: 1px solid $grey;
            border-left: 1px solid $grey;
            display: inline-block;
            height: 6px;
            position: absolute;
            right: 3px;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            width: 6px;
        }
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        border: 0;
        cursor: pointer;
        outline: none;
        padding: 5px 13px 5px 5px;
    }

    &__user-avatar {
        display: none;

        figure {
            background-color: $primary;
            background-image: url('../images/user-avatar-default.svg');
            background-position: center;
            background-size: cover;
            border-radius: 100%;
            display: inline-block;
            height: 38px;
            margin: 0;
            margin-left: auto;
            width: 38px;
        }

        i {
            border-bottom: 1px solid $primary;
            border-left: 1px solid $primary;
            display: inline-block;
            height: 6px;
            margin-left: 10px;
            transform: rotate(-45deg);
            width: 6px;
        }
    }

    &__socials {
        display: none;
        margin-bottom: 70px;
        text-align: center;

        p {
            color: $primary;
            margin-bottom: 15px;
        }

        a {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        svg {
            * {
                fill: $primary;
            }
        }
    }

    &__user-nav {
        position: relative;

        #main-nav__user-nav__toggle {
            display: none;

            &:checked {
                & + label {
                    background: white;
                    box-shadow: 0 0 30px rgba(black, 0.15);
                }

                & + label + ul {
                    opacity: 1;
                    pointer-events: initial;
                    transform: translateY(0);
                    visibility: visible;
                }
            }
        }

        label {
            align-items: center;
            color: $primary;
            cursor: pointer;
            display: flex;
            font-size: 15px;
            font-weight: bold;
            padding: 10px 18px;
            transition: all .3s;

            .main-nav__user-avatar {
                display: inline-block;
                margin-right: 14px;
            }

            i {
                border-bottom: 1px solid $primary;
                border-left: 1px solid $primary;
                display: inline-block;
                height: 6px;
                margin-left: 10px;
                transform: rotate(-45deg);
                width: 6px;
            }
        }

        ul {
            background: white;
            box-shadow: 0 0 30px rgba(black, 0.15);
            min-width: 100%;
            opacity: 0;
            padding: 7px 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 100%;
            transform: translateY(-10px);
            transition: all .2s;
            visibility: hidden;
            z-index: 2000;

            li {
                margin: 0;
                width: 100%;
            }

            a {
                display: inline-block;
                padding: 7px 20px !important;
                transition: all .3s;
                white-space: nowrap;
                width: 100%;

                &:hover {
                    background: #fafafa;
                }

                &:after {
                    display: none !important;
                }

                &.highlight {
                    color: $primary !important;
                }
            }
        }
    }

    #main-nav__user-nav__toggle__mobile {
        display: none;

        &:checked + label + ul {
            opacity: 1;
            pointer-events: initial;
            transform: translateY(0);
            visibility: visible;
        }
    }

    &__user-nav__mobile {
        display: none;
        position: relative;

        ul {
            background: white;
            box-shadow: 0 0 30px rgba(black, .15);
            opacity: 0;
            padding: 7px 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: calc(100% + 11px);
            transform: translateY(-10px);
            transition: all .2s;
            visibility: hidden;
            z-index: 500;

            a {
                display: inline-block;
                font-size: 14px !important;
                padding: 10px 35px 10px 20px !important;
                white-space: nowrap;
                width: 100%;

                &:after {
                    display: none !important;
                }

                &.highlight {
                    color: $primary !important;
                }
            }

            li {
                margin: 0;
            }
        }
    }

    @media (max-width: 890px) {
        background: white;
        box-shadow: 0 0 10px rgba(black, 0.1);
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;

        &__user-nav {
            display: none;
        }

        &__user-nav__mobile {
            display: flex;
        }

        #main-nav__mobile-toggle {
            &:checked + .container-fluid {
                .main-nav__menu-toggle {
                    label {
                        span {
                            &:nth-of-type(1) {
                                transform: translateY(5px);
                            }

                            &:nth-of-type(2) {
                                opacity: 0;
                                position: relative;
                                transform: translate(-5px, -50%);
                            }

                            &:nth-of-type(3) {
                                transform: translateY(-5px);
                            }
                        }
                    }
                }

                .main-nav__links {
                    opacity: 1;
                    pointer-events: initial;
                    visibility: visible;

                    & > .row {
                        & > [class*='col'] {
                            opacity: 1;
                            transform: translateY(0);

                            &:nth-of-type(1) {
                                transition-delay: .3s;
                            }

                            &:nth-of-type(2) {
                                transition-delay: .6s;
                            }

                            &:nth-of-type(3) {
                                transition-delay: .9s;
                            }
                        }
                    }
                }
            }
        }

        &__menu-toggle {
            display: block;
        }

        &__logo {
            img {
                height: 45px;
            }
        }

        &__links {
            background: white;
            height: 100vh;
            left: 0;
            opacity: 0;
            padding-top: 60px;
            pointer-events: none;
            position: absolute;
            top: 0;
            transition: all .5s;
            visibility: hidden;
            width: 100vw;
            z-index: -1;

            & > .row {
                align-items: flex-start !important;

                & > [class*='col'] {
                    margin-top: auto;
                    opacity: 0;
                    transform: translateY(-30px);
                    transition: all .5s;
                    width: 100%;

                    &:last-child {
                        li {
                            margin-bottom: 20px !important;
                        }
                    }
                }
            }

            ul {
                text-align: center !important;

                li {
                    margin: 0 0 25px !important;
                    width: 100%;
                }

                a:not(.button-primary):not(.button-tertiary):not(.button-primary-outline):not(.button-tertiary-outline) {
                    font-size: 18px;
                }
            }

            .main-nav__language {
                display: inline-block;
                margin: 0 auto;
                width: auto;
            }

            .list-inline {
                display: block !important;
            }
        }

        &__user-avatar {
            align-items: center;
            display: inline-flex;
        }

        &__socials {
            display: block;
        }
    }

    @include xs-only {
        height: 60px;

        .container-fluid {
            padding: 0 15px 0 20px;
        }

        &__logo {
            img {
                height: 35px;
            }

            a {
                span {
                    font-size: 14px;
                    margin-left: 15px;
                }
            }
        }
    }
}
