.custom-input {
    height: 50px;
    width: 100%;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 0 1px #eee;
    appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 0 20px;
    margin-bottom: 20px;
    outline: none;
    transition: all 0.2s;

    color: #5d5d5d;
    &::placeholder {
        color: #5d5d5d;
        opacity: 1;
    }

    &:focus {
        box-shadow: 0 0 0 6px rgba($primary, 0.2), 0 0 0 1px rgba($primary, 0.3);
    }
    &.small {
        font-size: 15px;
        height: 40px;
        padding: 0 15px;
    }

    @include xs-only {
        height: 40px;
        font-size: 14px;
        padding: 0 15px;
        margin-bottom: 15px;
    }
}

.custom-textarea {
    min-height: 150px;
    width: 100%;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 0 1px #eee;
    appearance: none;
    -webkit-appearance: none;
    font-size: 16px;
    padding: 15px 20px;
    margin-bottom: 20px;
    outline: none;
    transition: all 0.2s;
    &:focus {
        box-shadow: 0 0 0 6px rgba($primary, 0.2), 0 0 0 1px rgba($primary, 0.3);
    }
}

.custom-select {
    height: 43px;
    width: 100%;
    border-radius: 3px;
    border: none;
    box-shadow: 0 0 0 1px #eee;
    appearance: none;
    -webkit-appearance: none;
    font-size: 15px;
    padding: 0 15px;
    margin-bottom: 20px;
    outline: none;
    transition: all 0.2s;
    background-color: white;
    background-image: url(../images/select-arrow.svg);
    background-position: right 15px center;
    background-repeat: no-repeat;
    &:focus {
        box-shadow: 0 0 0 6px rgba($primary, 0.2), 0 0 0 1px rgba($primary, 0.3);
    }
    &.small {
        font-size: 15px;
        height: 40px;
        padding: 0 15px;
    }
}

.custom-checkbox {
    display: block;
    position: absolute;
    opacity: 0;

    &:checked + label {
        color: $primary;
        border-color: $primary;
        i {
            border-color: $primary;
            background: $primary;
        }
    }
    & + label {
        display: flex;
        align-items: center;
        padding: 13px;
        font-size: 15px;
        color: #5d5d5d;
        border-radius: 3px;
        border: 1px solid #eeeeee;
        cursor: pointer;
        background: white;
        transition: all 0.2s;
        i {
            height: 15px;
            width: 15px;
            border-radius: 2px;
            border: 1px solid #ddd;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            transition: all 0.2s;
            padding: 0 0 1px 0;
            &:before {
                content: '';
                transform: rotate(-45deg);
                height: 4px;
                width: 8px;
                border-left: 2px solid white;
                border-bottom: 2px solid white;
            }
        }
    }

    @include xs-only {
        & + label {
            padding: 9px 11px;
            font-size: 13px;
            i {
                height: 14px;
                width: 14px;
            }
        }
    }
}

.custom-radio {
    display: none;
    &:checked + label {
        color: $primary;
        border-color: $primary;
        i {
            border-color: $primary;
            background: $primary;
        }
    }
    & + label {
        display: flex;
        align-items: center;
        padding: 13px;
        font-size: 15px;
        color: #5d5d5d;
        border-radius: 40px;
        border: 1px solid #eeeeee;
        cursor: pointer;
        background: white;
        transition: all 0.2s;
        i {
            height: 16px;
            width: 16px;
            border-radius: 100%;
            border: 1px solid #ddd;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            transition: all 0.2s;
            padding: 0 0 1px 0;
            &:before {
                content: '';
                transform: rotate(-45deg);
                height: 4px;
                width: 8px;
                border-left: 2px solid white;
                border-bottom: 2px solid white;
            }
        }
    }

    @include xs-only {
        & + label {
            padding: 9px 11px;
            font-size: 13px;
            i {
                height: 14px;
                width: 14px;
            }
        }
    }
}

// @FIXME - tweak wysiwyg: https://www.tiny.cloud/docs/advanced/creating-a-skin/
.tox-tinymce {
    border: 1px solid #f6f6f6!important;
    border-radius: 3px!important;
    box-shadow: 0 0 0 1px #eee!important;
    margin:5px 0 20px;

    .tox-toolbar__primary {
        background: none!important;
        border-bottom: 2px solid #f6f6f6!important;
    }
    .tox-edit-area {
        padding: 0 9px!important;
    }

    @include xs-only{
        margin:5px 0 15px;
    }
}
