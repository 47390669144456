.home {
    &__header {
        overflow: hidden;
        margin-top: 15px;
        h1 {
            font-size: 55px;
            line-height: 55px;
            white-space: nowrap;
            strong {
                font-weight: normal;
                color: $primary;
            }
        }
        p {
            margin-top: 30px;
            font-size: 14px;
            line-height: 24px;
        }
        img {
            max-height: 550px;
            margin-left: -50px;
            position: relative;
            z-index: -1;
        }
        &__cta {
            margin-top: 50px;
            .button-primary {
                padding-left: 35px;
                padding-right: 35px;
            }
        }
        .text-slider {
            margin: 0 0 -10px;
            display: inline;
            strong {
                padding: 5px 0;
                white-space: nowrap;
            }
        }
        .scroll-down {
            margin-top: 40px;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            z-index: 10;
            position: relative;
            &:hover {
                span {
                    opacity: 0.7;
                }
            }
            span {
                display: block;
                text-transform: uppercase;
                font-weight: bold;
                color: $primary;
                font-size: 11px;
                transition: all 0.2s;
            }
            i {
                display: inline-block;
                height: 35px;
                width: 20px;
                border-radius: 30px;
                border: 2px solid $primary;
                margin-top: 10px;
                position: relative;
                &:before {
                    content: '';
                    height: 6px;
                    width: 6px;
                    border-radius: 100%;
                    background: $primary;
                    display: inline-block;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    animation: scroll-anim 3s infinite;
                }
            }
        }

        @keyframes scroll-anim {
            0% {
                top: 5px;
                opacity: 1;
            }
            50% {
                top: 22px;
                opacity: 0;
            }
            60% {
                top: 5px;
                opacity: 1;
            }
        }
    }

    &__form {
        padding-top: 50px;
        position: relative;
        z-index: 10;
        .main {
            padding: 50px 60px;
            border-radius: 50px 0 0 50px;
            height: 100%;
            background: #f8f9fa;
        }
        h2 {
            font-size: 35px;
            line-height: 35px;
            margin-top: 5px;
            margin-bottom: 25px;
        }
        h3 {
            font-size: 25px;
            color: $primary;
            font-weight: 700;
        }
        h4 {
            font-size: 25px;
            color: white;
            font-weight: 700;
        }
        .col-sm-6 {
            &:first-of-type {
                .choices {
                    margin-right: -2px;
                }
            }
            .choices {
                &.is-open {
                    position: relative;
                    z-index: 5;
                }
                &__inner {
                    border-width: 1px;
                }
            }
            input[gmaps-autocomplete] {
                border: 1px solid #f0f0f1;
                height: 100%;
                padding: 1rem;
                width: 100%;

                &::placeholder {
                    color: #5d5d5d;
                    opacity: 1;
                }

                &:focus-visible {
                    outline: initial;
                }
            }
        }
        .choices {
            &__inner {
                border-radius: 0 !important;
                background: white;
                padding-top: 10px;
            }
        }
        .button-primary {
            height: 100%;
            border-radius: inherit;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            font-size: 15px;
        }
        .aside {
            padding: 50px 60px;
            background: $tertiary;
            border-radius: 0 50px 50px 0;
            height: 100%;
            h2 {
                color: white;
                margin-top: 8px;
            }
            .link-primary {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(50%, -50%);
            }
        }
    }

    &__offers {
        margin-top: 80px;
        padding-bottom: 80px;
        overflow: hidden;
        .button-primary {
            margin-top: 80px;
        }
    }

    &__bilingual-jobs {
        position: relative;
        margin-top: -80px;
        padding-top: 150px;
        padding-bottom: 200px;
        overflow: hidden;
        .top-img {
            position: absolute;
            left: 0;
            top: 0px;
            width: 20vw;
        }
        .bottom-img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 24vw;
        }
        a {
            display: inline-block;
            margin-bottom: 30px;
        }
        article {
            &:hover {
                img {
                    box-shadow: 0 0 0 10px rgba($primary, 0.1);
                }
                .more {
                    margin-top: 4px;
                    max-height: 20px;
                    transition-delay: 0s;
                    span {
                        transition-delay: 0.1s;
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }
        .flag {
            position: relative;
            img {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                transition: all 0.2s;
            }
            o {
                width: 23px;
                height: 23px;
                border-radius: 100%;
                background-color: $primary;
                font-size: 10px;
                font-weight: bold;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 0 5px rgba($primary, 0.25);
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        h4,
        p {
            font-size: 18px;
            color: $grey;
        }
        h4 {
            font-weight: bold;
        }
        p {
            padding: 3px 0;
        }
        .view-more {
            font-size: 18px;
            font-weight: bold;
            color: $primary;
            line-height: 22px;
            &:hover {
                o {
                    box-shadow: 0 0 0 11px rgba($primary, 0.25);
                }
            }
            o {
                color: white;
                font-size: 38px;
                margin: 0 0 0 8px;
            }
        }
        .more {
            margin-top: 0;
            max-height: 0;
            transition: all 0.2s;
            transition-delay: 0.2s;
            span {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: $primary;
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.2s;
                svg {
                    margin: 2px 0 0 3px;
                }
            }
        }
    }

    &__need-advice {
        background: $secondary;
        position: relative;
        margin-top: 300px;
        .top {
            width: calc(100% + 2px);
            position: absolute;
            left: 0;
            bottom: calc(100% - 5px);
        }
        .bottom {
            width: calc(100% + 20px);
            position: absolute;
            top: calc(100% - 5px);
            left: -10px;
        }
        .container {
            padding-top: 50px;
            padding-bottom: 150px;
        }
        h2 {
            font-size: 55px;
            color: white;
            text-align: left;
            line-height: 55px;
        }
        .button-primary {
            position: relative;
            z-index: 10;
            padding: 0 50px;
            margin-top: 30px;
        }
        h3 {
            font-size: 25px;
            line-height: 30px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            line-height: 26px;
        }
        .section3 {
            margin-top: -90px;
        }
    }

    &__good-company {
        text-align: center;
        margin-top: -50px;
        overflow: hidden;
        .logos {
            img {
                opacity: 0.5;
                filter: grayscale(1) brightness(0.7);
            }
        }
    }

    &__ready-to-apply {
        margin-top: 130px;
        .content {
            text-align: center;
            background: $tertiary;
            border-radius: 50px;
            color: white;
            padding: 50px 40px;
        }
        h2,
        h3 {
            color: white;
        }
        h3 {
            margin-bottom: 45px;
        }
        .button-primary {
            padding-left: 45px;
            padding-right: 45px;
        }
        p {
            margin-top: 40px;
            font-size: 12px;
            line-height: 25px;
        }
    }
}

@include lg-down {
    .home {
        &__offers {
            article {
                header {
                    height: 110px;
                }
            }
        }
        &__need-advice {
            margin-top: 180px;
        }
        &__footer {
            .wave {
                width: calc(100% + 12px);
                left: -12px;
            }
        }
    }
}

@include md-down {
    .home {
        &__form {
            h2,
            h3,
            h4 {
                text-align: center;
            }
            .aside {
                border-radius: 0 0 50px 50px;
                .link-primary {
                    right: 50%;
                    top: 100%;
                    transform: translate(50%, -50%);
                }
            }
        }
        &__offers {
            h2 {
                margin-bottom: 50px;
            }
        }
        &__bilingual-jobs {
            padding-bottom: 170px;
        }
        &__good-company {
            margin-top: 40px;
            h2 {
                margin-bottom: 50px;
            }
        }
        &__need-advice {
            margin-top: 150px;
            h2 {
                font-size: 40px;
                line-height: 40px;
                margin-bottom: 50px;
            }
        }
    }
}

@include sm-down {
    .home {
        &__header {
            padding-bottom: 20px;
            img {
                max-height: 400px;
                margin-left: -120px;
            }
            h1 {
                font-size: 32px;
                line-height: 32px;
                margin-top: 35px;
            }
            .scroll-down {
                display: none;
            }
            &__cta {
                margin-top: 25px;
            }
            h1 {
                white-space: initial;
            }
            .text-slider {
                display: block;
            }
        }

        &__form {
            padding-top: 30px;
            .main {
                padding: 30px 35px;
            }
            h3 {
                font-size: 18px;
            }
            h2 {
                font-size: 25px;
                line-height: 25px;
            }
            h4 {
                font-size: 25px;
            }
            .col-sm-6 {
                &:last-of-type {
                    .choices__inner {
                        border-radius: 0 3px 3px 0 !important;
                    }
                }
            }
            .button-primary {
                width: 100%;
                border-radius: 40px;
                margin-top: 20px;
            }
            .aside {
                padding: 30px 35px;
                h2 {
                    font-size: 18px;
                    margin-bottom: 20px;
                }
            }
        }

        &__offers {
            h2 {
                margin-bottom: 30px;
                font-size: 25px;
                line-height: 25px;
            }
            .action {
                margin-top: 30px;
            }
            article {
                border-radius: 40px;
                main {
                    padding: 20px 20px 25px;
                }
                h3 {
                    font-size: 16px;
                    line-height: 18px;
                }
                p {
                    font-size: 13px;
                    line-height: 13px;
                }
                small {
                    font-size: 13px;
                    margin-bottom: 15px;
                }
                .flag {
                    height: 35px;
                    width: 35px;
                }
            }
        }

        &__bilingual-jobs {
            padding-top: 100px;
            padding-bottom: 220px;
            h2 {
                font-size: 18px;
                line-height: 18px;
            }
            h3 {
                font-size: 25px;
                margin-bottom: 40px;
            }
            h4,
            p {
                font-size: 16px;
            }
            .row {
                margin: 0 -10px;
                & > [class*='col-'] {
                    padding: 0 10px;
                }
            }
            .more {
                span {
                    font-size: 12px;
                }
            }
            .view-more {
                font-size: 16px;
                line-height: 20px;
            }
            .top-img {
                top: initial;
                bottom: 0;
                width: 28vw;
            }
            .bottom-img {
                width: 35vw;
                bottom: 20px;
            }
        }
        &__good-company {
            margin-top: 30px;
            p {
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 5px;
            }
            h2 {
                font-size: 25px;
                line-height: 28px;
            }
        }
        &__need-advice {
            .container {
                padding-bottom: 50px;
            }
            h2 {
                font-size: 25px;
                line-height: 30px;
                text-align: center;
                margin-bottom: 30px;
            }
            h3 {
                font-size: 20px;
                line-height: 20px;
            }
            p {
                font-size: 15px;
                line-height: 20px;
            }
            .section1,
            .section2,
            .section3 {
                img {
                    max-height: 300px;
                }
            }
            .section3 {
                margin-top: 0;
            }
        }
        &__ready-to-apply {
            margin-top: 80px;
            .content {
                padding: 40px 30px;
            }
            h2 {
                font-size: 18px;
                line-height: 18px;
            }
            h3 {
                font-size: 25px;
                line-height: 25px;
                margin-bottom: 30px;
            }
        }
    }
}

@include xs-only {
    .home {
        &__header {
            margin-top: 0;
            img {
                max-height: 300px;
                margin-left: 50%;
                transform: translateX(-50%);
            }
            h1 {
                margin-top: 25px;
                font-size: 22px;
                line-height: 22px;
            }
            p {
                font-size: 13px;
                line-height: 20px;
                margin-top: 20px;
            }
            .text-slider {
                display: inline;
            }
        }
        &__form {
            h3 {
                font-size: 16px;
            }
            h2 {
                font-size: 20px;
                line-height: 24px;
            }
            h4 {
                font-size: 20px;
            }
            .col-sm-6 {
                &:first-of-type {
                    .choices__inner {
                        border-radius: 4px !important;
                    }
                }
                &:last-of-type {
                    margin-top: 15px;
                    .choices__inner {
                        border-radius: 4px !important;
                    }
                }
            }
        }
        &__offers {
            h2 {
                font-size: 20px;
                line-height: 20px;
            }
            button {
                height: 35px;
                width: 35px;
                &.prev {
                    margin-right: -35px;
                }
                &.next {
                    margin-left: -35px;
                }
            }
        }
        &__bilingual-jobs {
            padding-bottom: 250px;
            h3 {
                font-size: 20px;
            }
            h4,
            p {
                font-size: 14px;
                display: block;
            }
            a {
                margin-bottom: 20px;
            }
            article {
                .flag {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
            .view-more {
                font-size: 14px;
                line-height: 16px;
                o {
                    margin-left: 3px;
                }
            }
            .top-img {
                width: 40vw;
            }
            .bottom-img {
                width: 45vw;
            }
        }
        &__good-company {
            p {
                font-size: 16px;
            }
            h2 {
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 30px;
            }
        }
        &__need-advice {
            margin-top: 80px;
            h2 {
                font-size: 20px;
                line-height: 20px;
            }
            h3 {
                font-size: 18px;
                line-height: 18px;
            }
            p {
                font-size: 14px;
                line-height: 18px;
            }
            .button-primary {
                margin-left: auto;
                margin-right: auto;
                padding: 0 25px;
            }
            .section1,
            .section2,
            .section3 {
                img {
                    max-height: 280px;
                    margin-bottom: 30px;
                }
            }
        }
        &__ready-to-apply {
            margin-top: 60px;
            .content {
                padding: 30px 25px;
            }
            h2 {
                font-size: 16px;
                line-height: 16px;
            }
            h3 {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}

@media (max-width: 380px) {
    .home {
        &__bilingual-jobs {
            padding-bottom: 200px;
        }
    }
}
