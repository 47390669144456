.blog-post {
    &__breadcrumb {
        font-size: 12px;
        line-height: 20px;

        a {
            color: $grey;
            text-decoration: underline;
        }
    }

    &__wrapper {
        h1 {
            color: $tertiary;
            font-size: 45px;
            font-weight: bold;
            margin-bottom: 4rem;
        }
    }

    &__infos {
        font-size: 20px;

        span {
            color: $primary;
        }
    }

    &__content {
        margin-top: 60px;

        p {
            font-size: 18px !important;
            line-height: 26px !important;
            margin: 25px 0 30px;
            &:empty {
                display: none !important;
            }
            & + ol,
            & + ul {
                margin-top: -10px;
            }
        }
        ol,
        ul {
            font-size: 18px !important;
            line-height: 26px !important;
            margin: 10px 0 30px;
            padding-left: 20px;
            li {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        // * {
        //     color: #333;
        //     font-size: 18px;
        //     line-height: 26px;
        // }

        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $tertiary !important;
            font-weight: bold;
            margin: 30px 0 20px;
            & + p {
                margin-top: 20px;
            }
            & + ol,
            & + ul {
                margin-top: -10px;
            }
        }
        h2 {
            font-size: 26px !important;
            line-height: 32px !important;
        }
        h3 {
            font-size: 22px !important;
            line-height: 28px !important;
        }
        h4,
        h5,
        h6 {
            font-size: 20px !important;
            line-height: 26px !important;
        }
        a {
            color: $primary;
            font-weight: bold;
            transition: all 0.2s;
            &:hover {
                opacity: 0.7;
            }
        }

        .video-container {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%;

            .video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        &__author {
            text-align: center;
            margin: 50px 0 30px;
            figure {
                height: 130px;
                width: 130px;
                border-radius: 100%;
                background-color: #eee;
                display: inline-block;
            }
            span {
                display: block;
                font-size: 15px !important;
                margin-top: 10px;
            }
            h6 {
                font-size: 21px !important;
                margin-top: 0 !important;
                color: $primary !important;
            }
        }
    }
    &__more {
        h5 {
            font-size: 25px;
            color: $tertiary;
            font-weight: bold;
        }
        &__subtitle {
            color: $tertiary;
            margin-top: 10px;
        }
    }

    @include md-down {
        &__wrapper {
            h1 {
                font-size: 35px;
            }
        }
    }

    @include xs-only {
        &__wrapper {
            h1 {
                font-size: 25px;
            }
        }
        &__infos {
            font-size: 16px;
        }
        &__content {
            margin-top: 40px;
            p {
                font-size: 16px !important;
                line-height: 24px !important;
                margin: 25px 0;
            }
            * {
                font-size: 16px !important;
            }
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $tertiary !important;
                margin: 25px 0;
            }
            h2 {
                font-size: 22px !important;
                line-height: 28px !important;
            }
            h3 {
                font-size: 18px !important;
            }
            h4,
            h5,
            h6 {
                font-size: 16px !important;
            }
        }
    }
}
