body.jobs-details{
  .main-nav{
    background-color:transparent;
    &.scrolled{background-color:white;}
  }
}

.single-job{
  &__main-content{
    position:relative;
    .img-top{position:absolute; bottom:-100px; left:0; width:28vw;}
    @include md-down{
      .img-top{bottom:-20px;}
    }
  }
  &__bg{position:absolute; top:0; left:0; z-index:-1; width:90vw; user-select:none;}
  &__goback{
    margin:30px 0; 
    a{
      display:inline-flex; align-items:center; font-size:15px; font-weight:bold; color:$primary;
      &:hover{
        o{box-shadow:0 0 0 11px rgba($primary, .25);}
      }
      o{margin-right:20px; height:40px; width:40px; padding:0 2px 0 0;}
    }
    @include xs-only{
      margin-bottom:20px;
    }
  }
  &__aside{
    overflow:hidden; padding-bottom:10px;
    @include lg-up{position:sticky; top:120px;}
    &__wrapper{
      @include xl-only{padding-bottom:350px;}
      @include lg-only{padding-bottom:250px;}
    }
    .content{background:#EBEDF2; border-radius:50px;}
    figure{
      height:150px; background:white; border:2px solid #EBEDF2; border-radius:50px 50px 0 0; display:flex; align-items:center; justify-content:center;
      img{max-width:70%; max-height:60%;}
    }
    main{padding:20px 30px 50px;}
    h4{font-size:18px; line-height:25px;}
    ul{
      margin-top:20px;
      li{
        display:flex; font-size:14px; line-height:18px;
        &:not(:last-child){margin-bottom:15px;}
      }
    }
    i, .flag{display:inline-flex; height:15px; width:15px; align-items:center; justify-content:center; margin-right:10px;}
    .flag{
      border-radius:100%; margin-right:0;
      &:not(:first-of-type){margin-left:-5px; box-shadow:0 0 0 2px #EBEDF2;}
      &:last-of-type{margin-right:10px;}
    }

    @include sm-down{
      margin-top:20px;
    }
  }
  &__breadcrumb{
    li,a {font-size:10px; color:rgba($grey, .8);}
    li{
      &:not(:last-child){margin-right:0;}
    }
  }
  &__content{
    h2{font-size:25px; color:$primary; font-weight:bold; text-transform:uppercase; margin-top:20px;}
    h1{font-size:35px; line-height:40px;}
    time{font-style:italic; font-size:12px; margin-top:10px;}
    @include xs-only{
      h2{font-size:20px; line-height:24px;}
      h1{font-size:25px; line-height:25px;}
    }
  }
  &__main{
    margin-top:50px; font-size:16px; line-height:26px;
    h3{
      display:flex; align-items:center; font-size:25px; line-height:30px; font-weight:bold; margin:20px 0;
      &:not(:first-child){margin-top:40px;}
      &:before{content:''; width:30px; height:2px; background:$primary; margin-right:8px; margin-top:2px;}
    }
    p, ul{font-size:16px; line-height:26px; margin-top:20px; margin-bottom:20px;}
    ul{list-style:none;}
    li:before{content: "•"; color:$primary; margin-right:7px;}
    @include xs-only{
      margin-top:30px;
      h3{
        font-size:20px; line-height:24px;
        &:before{width:25px;}
      }
      p{font-size:14px; line-height:24px; margin-top:10px; margin-bottom:30px;}
    }
  }
  &__apply{
    margin-top:25px;
    h4{color:$primary; margin-bottom:25px; font-size:35px; line-height:40px;}
    .favorite{
      font-size:16px; font-weight:bold; color:$primary; display:inline-block;
      img{margin-right:10px; margin-bottom:3px;}
    }

    @include md-down{
      margin-top:30px;
      h4{margin-bottom:0; font-size:30px; line-height:35px;}
      a{margin-top:20px;}
    }

    @include sm-down{
      margin-top:50px;
    }

    @include xs-only{
      margin-top:30px;
      .favorite{margin-top:20px;}
      .button-primary__wrapper{min-height:36px; margin-top:20px;}
      .button-primary{
        margin-top:0;
        &.fixed{position:fixed; bottom:60px; left:50%; transform:translateX(-50%); z-index:500;}
      }
    }
  }
  &__offers{
    margin-top:130px;
    h2{
      font-size:35px; line-height:40px;
      span{color:$primary;}
    }
    .jobs-slider{margin-top:90px;}
    @include md-down{
      margin-top:50px;
    }
    @include sm-down{
      margin-top:80px;
      .jobs-slider{margin-top:50px;}
    }
    @include xs-only{
      margin-top:40px;
      h2{font-size:25px; line-height:25px;}
      .jobs-slider{margin-top:30px;}
    }
  }
  &__socials{
    ul{
      li{
        &:not(:last-child){margin-bottom:15px;}
      }
      a{
        transition:all .2s; padding:10px; margin-bottom:-10px;
        &:hover{
          opacity:.7;
        }
      }
    }
    @include sm-down{
      margin-top:50px !important;
      ul{
        li{
          &:not(:last-child){margin-right:20px;}
        }
      }
    }
  }
}