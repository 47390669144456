.login {
    &__logo {
        margin-top: 40px;
        transition: all 0.2s;
        display: inline-flex;
        align-items: center;
        &:hover {
            opacity: 0.7;
        }
        span {
            font-size: 18px;
            color: $tertiary;
            font-weight: bold;
            margin-left: 20px;
        }
    }
    &__title {
        font-size: 30px;
        margin-top: 40px;
        strong {
            font-weight: normal;
            color: $primary;
        }
    }
    &__subtitle {
        font-size: 18px;
        margin-top: 5px;
        a {
            color: $primary;
            font-weight: 700;
        }
    }
    &__desc {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    &__social-auth {
        a {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            strong {
                margin-left: 3px;
            }
            img {
                margin-right: 15px;
            }
        }
    }
    &__form {
        input[type='submit'] {
            margin-top: 20px;
        }
        .password-lost {
            font-size: 15px;
            font-weight: 700;
            color: $primary;
            text-decoration: underline;
        }
        .input-password {
            position: relative;
            margin-bottom: 20px;
            .custom-input {
                margin-bottom: 0;
                padding-right: 60px;
            }
            .password-revealer {
                display: none;
                &:checked + label {
                    opacity: 1;
                    &:after {
                        opacity: 1;
                    }
                }
                & + label {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                    padding: 10px;
                    cursor: pointer;
                    opacity: 0.5;
                    transition: all 0.2s;
                    &:hover {
                        opacity: 0.8;
                    }
                    &:after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 3px;
                        width: 25px;
                        background: black;
                        transform: translate(-50%, -50%) rotate(45deg);
                        box-shadow: 0 0 0 2px white;
                        transition: all 0.2s;
                        opacity: 0;
                    }
                }
            }
        }
    }
    &__switch {
        background: rgba($tertiary, 0.05);
        border-radius: 5px;
        padding: 20px 25px;
        margin-top: 50px;
        margin-bottom: 40px;
        h2 {
            font-weight: 700;
            color: $tertiary;
            font-size: 22px;
        }
        p {
            margin-top: 5px;
            font-size: 15px;
            line-height: 20px;
            color: #333;
        }
        a {
            margin-top: 10px;
            font-size: 15px;
            color: $tertiary;
            display: inline-block;
            text-decoration: underline;
            font-weight: 700;
        }
    }

    &__aside {
        text-align: center;
        .content {
            min-height: 100%;
            padding: 130px 40px;
            position: relative;
            background: $secondary;
            box-shadow: 0 0 0 30px #f4f4f4;
        }
        .main-content {
            background: $secondary;
            padding: 0 20px !important;
        }
        h3 {
            font-size: 26px;
            color: white;
            font-weight: 700;
        }
        h2 {
            font-size: 20px;
            color: white;
            margin-top: 10px;
        }
        .logos {
            margin-top: 45px;
            img {
                max-height: 80px;
                margin-bottom: 45px;
                max-width: 250px;
            }
        }
    }

    @include md-down {
        &__aside {
            .content {
                padding: 135px 30px 10px;
            }
            .logos {
                img {
                    max-height: 50px;
                    max-width: 180px;
                }
            }
        }
    }

    @include sm-down {
        &__aside {
            margin-top: 50px;
            .content {
                padding: 50px 30px 10px;
            }
        }
    }

    @include xs-only {
        &__logo {
            margin-top: 20px;
            img {
                height: 40px;
            }
            span {
                font-size: 15px;
            }
        }
        &__title {
            font-size: 24px;
            margin-top: 25px;
        }
        &__subtitle {
            font-size: 15px;
        }
        &__desc {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 25px;
        }
        &__form {
            .password-lost {
                font-size: 13px;
            }
            .input-password {
                margin-bottom: 15px;
                #password-revealer {
                    & + label {
                        right: 3px;
                    }
                }
            }
            .button-primary {
                width: 100%;
            }
        }
        &__switch {
            padding: 15px 20px;
            margin-top: 30px;
            margin-bottom: 25px;
            h2 {
                font-size: 17px;
            }
            p {
                font-size: 13px;
                line-height: 18px;
            }
            a {
                font-size: 13px;
            }
        }
        &__aside {
            h3 {
                font-size: 22px;
            }
            h2 {
                font-size: 16px;
            }
            .logos {
                img {
                    max-height: 50px;
                    max-width: 140px;
                }
            }
        }
    }
}

body.register_company,
.container.login-recruteur {
    .login {
        &__title {
            strong {
                color: $tertiary;
            }
        }
        &__aside {
            .content {
                background: $tertiary;
            }
        }
    }
}

p.cgu {
    font-size: 11px;
    margin-top: 30px;
}
