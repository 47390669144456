.post-job {
    &__title {
        font-size: 26px;
        font-weight: 700;
        color: $primary;
        margin-top: 30px;
    }
    &__form {
        margin-top: 30px;
        &__label {
            font-size: 18px;
            font-weight: 700;
            color: $tertiary;
            margin-bottom: 5px;
            &.normal {
                font-weight: 400;
            }
            &.has-error {
                color: $primary;
            }
        }
        &__sublabel {
            font-size: 15px;
            line-height: 20px;
            color: #5d5d5d;
            margin-bottom: 10px;
            display: block;
        }
        .custom-input,
        .custom-textarea {
            margin-top: 5px;
        }
        .custom-checkbox,
        .custom-radio {
            & + label {
                margin-bottom: 15px;
            }
        }
        section {
            &:not(:last-of-type) {
                padding-bottom: 15px;
                margin-bottom: 30px;
                border-bottom: 1px solid #eeeeee;
            }
        }
    }
    &__infos {
        margin-top: 10px;
        background: #fafafa;
        padding: 15px 20px;
        font-size: 16px;
        color: #5d5d5d;
        line-height: 20px;
        margin-bottom: 35px;
    }
    &__settings {
        padding: 20px 25px 10px;
        border-radius: 5px;
        border: 5px solid rgba($tertiary, 0.1);
        margin-top: 20px;
    }
    &__aside {
        position: sticky;
        top: 110px;
        h1 {
            font-size: 20px;
            color: $primary;
            font-weight: 600;
        }
        .company-logo {
            max-height: 100px;
            max-width: 180px;
        }
        h4 {
            font-size: 18px;
            color: #333;
            margin-top: 30px;
            font-weight: 700;
        }
    }
    &__indicator {
        padding: 15px 20px;
        border-radius: 10px;
        border: 1px solid #eee;
        margin-top: 15px;
        h2 {
            font-size: 16px;
            font-weight: 700;
            color: $tertiary;
        }
        p {
            margin-top: 5px;
            font-size: 14px;
            line-height: 20px;
            strong {
                color: #3bbfa6;
            }
        }
    }
    &__question {
        padding: 25px 35px;
        border-radius: 10px;
        background: $tertiary;
        color: white;
        text-align: center;
        margin-top: 30px;
        h3 {
            color: white;
            font-size: 22px;
        }
        p {
            color: white;
            font-size: 15px;
            line-height: 18px;
            margin-top: 10px;
        }
        a:not(.button-white) {
            margin-top: 20px;
            font-size: 24px;
            color: white;
            text-decoration: underline;
            font-weight: 700;
            display: inline-block;
        }
        .button-white {
            margin-top: 25px;
        }
    }
    &__languages {
        .add {
            border: none;
            padding: 0;
            color: $tertiary;
            font-weight: 700;
            text-decoration: underline;
            background: none;
            font-size: 14px;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
