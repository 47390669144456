.job-list {
    &__header {
        margin-top: 100px;
        position: relative;
        .top-img {
            position: absolute;
            left: 0;
            top: -80px;
            width: 20vw;
            z-index: 200;
        }
        @include xs-only {
            margin-top: 40px;
            h1 {
                font-size: 15px;
            }
            h3 {
                font-size: 25px;
                line-height: 25px;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
    }
    &__jobs {
        position: relative;
        &.loading {
            .loader {
                opacity: 1;
                visibility: visible;
                pointer-events: initial;
            }
        }
        .loader {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: all 0.2s;
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(white, 0.7);
            text-align: center;
            img {
                margin-top: 50px;
            }
        }

        .job-card {
            border: 1px solid #a1a1a1;
            background-color: #f8f9fa;
            border-radius: 50px;
            margin-bottom: 40px;
            position: relative;
            padding-right: 45px;
            .link {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
            }
            header {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: white;
                border-radius: 50px 0 0 50px;
                padding: 20px 30px;
                transition: all 0.2s;
                &:hover {
                    opacity: 0.8;
                }
                img {
                    max-width: 100%;
                    max-height: 110px;
                }
            }
            &.virtual {
                border: 1px solid #ebedf2;
            }
            &__details {
                padding-top: 25px !important;
                padding-bottom: 25px !important;
            }
            h4 {
                font-size: 14px;
                color: $primary;
                text-transform: uppercase;
            }
            h2 {
                font-size: 18px;
                color: $primary;
                margin-top: 5px;
                font-weight: bold;
                transition: all 0.2s;
                &:hover {
                    opacity: 0.8;
                }
            }
            p {
                font-size: 14px;
                margin-top: 15px;
                img {
                    margin-right: 5px;
                }
            }
            .publish {
                font-size: 12px;
                font-weight: 300;
                font-style: italic;
                color: $grey;
            }
            time {
                font-size: 12px;
                font-style: italic;
                margin-top: 10px;
                display: block;

                .declined {
                    color: $primary;
                    font-weight: 600;
                    margin-left: 4px;
                }
            }
            .country {
                margin-top: 15px;
                font-size: 14px;
            }
            .flag {
                height: 27px;
                width: 27px;
                border-radius: 100%;
                border: 1px solid #eee;
                &:not(:first-of-type) {
                    margin-left: -10px;
                }
                &:last-of-type {
                    margin-right: 10px;
                }
            }
            .progress {
                font-size: 14px;
                .circle {
                    position: relative;
                    margin-left: 10px;
                }
                svg {
                    transform: rotate(-90deg);
                }
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    font-weight: bold;
                    font-style: initial;
                }
            }
            &__link {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(50%, -50%);
                padding: 1px 0 0 3px;
                z-index: 2;
                img {
                    transform: rotate(180deg);
                }
            }
            footer {
                font-size: 15px;
                font-weight: bold;
                color: $primary;
                margin-top: 15px;
                padding-top: 20px;
                border-top: 1px solid white;
                .link-primary {
                    padding: 0 0 0 3px;
                    height: 40px;
                    width: 40px;
                    margin-left: 25px;
                }
                o {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
            .user-pp {
                height: 150px;
                width: 150px;
                border-radius: 100%;
                background-size: cover;
                background-color: $primary;
                background-image: url('../images/default-avatar.svg');
                &.no-pp {
                    background-size: 60%;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            .languages {
                line-height: 18px;
            }

            @include xs-only {
                padding-right: 0;
                border-radius: 40px;
                header {
                    border-radius: 40px 40px 0 0;
                    img {
                        max-height: 80px;
                    }
                }
                &__details {
                    padding: 20px 40px !important;
                }
                .country,
                .progress {
                    margin-top: 5px;
                }
                .progress {
                    .circle {
                        margin-left: 0;
                    }
                    svg {
                        height: 35px;
                    }
                    i {
                        font-size: 11px;
                        top: calc(50% - 2px);
                    }
                }
                p {
                    margin-top: 10px;
                }
            }
        }
    }
    &__looking-for-job {
        margin-bottom: 50px;
        margin-top: 10px;
        img {
            width: 110px;
            height: 110px;
        }
        h2 {
            font-size: 25px;
        }
        p {
            font-size: 14px;
            line-height: 24px;
            margin-top: 15px;
        }
        .button-primary {
            margin-top: 20px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @include sm-down {
            img {
                margin-bottom: 20px;
            }
        }
        @include xs-only {
            img {
                height: 90px;
            }
            h2 {
                font-size: 20px;
            }
        }
    }
}
