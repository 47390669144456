.candidate-details {
    &__back {
        font-size: 16px;
        color: #5d5d5d !important;
        display: flex;
        align-items: center;
        margin-top: 30px;
        i {
            height: 8px;
            width: 8px;
            display: inline-block;
            border-left: 1px solid #5d5d5d;
            border-bottom: 1px solid #5d5d5d;
            transform: rotate(45deg);
            margin-right: 7px;
        }
    }
    &__header {
        .button-primary-outline {
            border-radius: 4px;
            padding: 0 12px;
        }
        .declined {
            color: $primary;
            font-size: 12px;
            font-weight: 600;
            padding: 15px;
        }
    }
    &__aside {
        background: #ebedf2;
        border-radius: 50px;
        position: sticky;
        top: 120px;
        figure {
            min-height: 100px;
            background: white;
            border: 2px solid #ebedf2;
            border-radius: 50px 50px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            width: 100%;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        main {
            padding: 20px 30px 50px;
        }
        h4 {
            font-size: 18px;
            line-height: 22px;
            color: $primary;
            small {
                color: $tertiary;
            }
        }
        ul {
            margin-top: 10px;
            li {
                display: flex;
                font-size: 14px;
                line-height: 18px;
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
        i,
        .flag {
            display: inline-flex;
            height: 15px;
            width: 15px;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
        .flag {
            border-radius: 100%;
            margin-right: 0;
            &:not(:first-of-type) {
                margin-left: -5px;
                box-shadow: 0 0 0 2px #ebedf2;
            }
            &:last-of-type {
                margin-right: 10px;
            }
        }
    }
    &__content {
        & > [class*='col'] {
            margin-top: 30px;
        }
        iframe {
            height: 100%;
            min-height: 1195px;
            width: 100%;
            margin-top: 25px;
        }
    }
    &__pp {
        border-radius: 4px;
    }
    &__modal {
        .modal-content {
            border-radius: 10px;
        }
        .modal-header {
            border-bottom: none;
            padding: 20px 25px 0;
        }
        .modal-close {
            margin-top: 5px;
            transition: all 0.2s;
            &:hover {
                opacity: 0.5;
            }
            img {
                height: 20px;
            }
        }
        .modal-title {
            color: $primary;
            font-weight: bold;
        }
        .modal-body {
            padding: 0 25px 0;
        }
        .modal-footer {
            padding: 10px 25px 25px;
            border-top: 0;
        }
    }

    @include lg-down {
        &__content {
            iframe {
                min-height: 855px;
            }
        }
    }

    @include md-down {
        &__content {
            iframe {
                min-height: 535px;
            }
        }
    }

    @include sm-down {
        &__header {
            .actions {
                margin-top: 10px;
                [class*='button-'] {
                    margin-top: 10px;
                }
            }
        }
        &__content {
            margin-bottom: 140px !important;
            ul {
                margin-top: 0;
            }
            iframe {
                min-height: 725px;
            }
        }
        &__pp {
            margin-bottom: 20px;
        }
    }

    @include xs-only {
        &__header {
            h1 {
                font-size: 22px;
            }
            h2 {
                font-size: 14px;
            }
        }
        &__content {
            ul {
                li {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            iframe {
                min-height: 540px;
            }
        }
    }
}
