// account style for company/candidate

.home-account {
    h1 {
        color: $primary;
        font-size: 26px;
        font-weight: 700;
        margin-top: 30px;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;

        &.label {
            color: $tertiary;
        }
    }
}

.filter-blue {
    filter: invert(28%) sepia(10%) saturate(3070%) hue-rotate(183deg) brightness(92%) contrast(89%);
    height: 80px;
    margin: 25px 0 10px;
}
