.recruiter {
    &__header {
        overflow: hidden;
        margin-top: 15px;
        h1 {
            font-size: 55px;
            line-height: 55px;
            white-space: nowrap;
            strong {
                font-weight: normal;
                color: $primary;
            }
        }
        p {
            margin-top: 30px;
            font-size: 14px;
            line-height: 24px;
        }
        img {
            max-height: 450px;
            margin-left: -20px;
            position: relative;
            z-index: -1;
        }
        &__cta {
            margin-top: 50px;
            .button-primary {
                padding-left: 35px;
                padding-right: 35px;
            }
        }
        .text-slider {
            margin: 0 0 -10px;
            display: inline;
            strong {
                padding: 5px 0;
                white-space: nowrap;
            }
        }
        @include sm-down {
            padding-bottom: 20px;
            img {
                max-height: 400px;
                margin-left: -120px;
            }
            h1 {
                font-size: 32px;
                line-height: 32px;
                margin-top: 35px;
            }
            .scroll-down {
                display: none;
            }
            &__cta {
                margin-top: 25px;
            }
            h1 {
                white-space: initial;
            }
            .text-slider {
                display: block;
            }
        }
        @include xs-only {
            margin-top: 0;
            img {
                max-height: 300px;
                margin-left: 50%;
                transform: translateX(-50%);
            }
            h1 {
                margin-top: 25px;
                font-size: 22px;
                line-height: 22px;
            }
            p {
                font-size: 13px;
                line-height: 20px;
                margin-top: 20px;
            }
            .text-slider {
                display: inline;
            }
        }
    }
    &__candidates {
        margin-top: 80px;
        .button-primary {
            margin-top: 60px;
        }

        .candidates-slider {
            position: relative;
            margin-top: 120px;

            .slick-list {
                overflow: visible;
                position: relative;
            }

            .slick-track {
                display: flex !important;
            }

            .slick-slide {
                padding: 0 7.5px;
                height: inherit !important;
                & > div,
                article {
                    height: 100%;
                }
                article {
                    transition: all 0.5s;
                }
                &:not(.slick-active) article {
                    opacity: 0.2;
                    pointer-events: none;
                }
            }

            button {
                height: 45px;
                width: 45px;
                border-radius: 100%;
                background: $primary;
                box-shadow: 0 0 0 8px rgba($primary, 0.2);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                z-index: 100;
                transition: all 0.2s;

                &:hover {
                    box-shadow: 0 0 0 10px rgba($primary, 0.2);
                }

                &.prev {
                    right: 100%;
                    margin-right: 25px;
                }

                &.next {
                    left: 100%;
                    margin-left: 25px;
                    padding-left: 3px;

                    img {
                        transform: rotate(180deg);
                    }
                }
            }

            article {
                border-radius: 50px;
                padding: 0 45px 55px;
                background: #f8f9fa;
                border: 1px solid #ebedf2;
                text-align: center;

                figure {
                    position: relative;
                    display: inline-block;
                }

                .profile-picture {
                    height: 145px;
                    border-radius: 100%;
                    margin-top: -50px;
                    display: inline-block;
                }

                .flag {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 40px;
                    width: 40px;
                    border-radius: 100%;
                }

                img {
                    &.flag {
                        margin-right: 10px;
                    }
                }

                h5 {
                    font-size: 18px;
                    font-weight: bold;
                    color: $primary;
                    margin: 30px 0;
                }

                .content {
                    // display: flex;
                    // align-items: flex-end;
                    text-align: left;

                    span {
                        display: inline-block;
                        font-size: 14px;
                        line-height: 19px;

                        &.language {
                            margin-bottom: 14px;
                        }
                        &.fonction {
                            font-size: 12px;
                            display: block;
                        }
                        &.register {
                            display: block;
                            position: absolute;
                            bottom: 20px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        @include lg-down {
            .candidates-slider {
                article {
                    padding: 0 30px 45px;
                    .profile-picture {
                        height: 110px;
                    }
                }
            }
        }
        @include md-down {
            h2 {
                margin-bottom: 50px;
            }
        }
        @include sm-down {
            h2 {
                margin-bottom: 30px;
                font-size: 25px;
                line-height: 25px;
            }
        }
        @include xs-only {
            margin-top: 40px;
            h2 {
                font-size: 20px;
                line-height: 22px;
            }
            .button-primary {
                margin-top: 40px;
            }
            .candidates-slider {
                padding: 0 35px;
                margin-top: 90px;
                button {
                    height: 35px;
                    width: 35px;
                    &.prev {
                        margin-right: -15px;
                    }
                    &.next {
                        margin-left: -15px;
                    }
                }
                article {
                    padding: 0 20px 35px;
                }
            }
        }
    }
    &__numbers {
        padding: 60px 0;
        background: #f8f9fa;
        text-align: center;
        margin-top: 80px;
        .row {
            & > [class*='col']:not(:last-of-type) {
                border-right: 1px solid #f0dedf;
            }
        }
        span {
            font-size: 60px;
            font-weight: bold;
            color: $primary;
        }
        h2 {
            font-size: 25px;
        }
        @include lg-down {
            span {
                font-size: 50px;
            }
            h2 {
                font-size: 22px;
            }
        }
        @include md-down {
            span {
                font-size: 40px;
            }
            h2 {
                font-size: 16px;
                margin-top: 5px;
            }
        }
        @include sm-down {
            padding: 60px 0 40px;
            h2 {
                font-size: 16px;
            }
            .row {
                & > [class*='col'] {
                    margin-bottom: 20px;
                    border: none !important;
                    &:nth-of-type(2n - 1) {
                        border-right: 1px solid #f0dedf !important;
                    }
                }
            }
        }
        @include xs-only {
            margin-top: 50px;
            padding: 35px 0 15px;
            h2 {
                font-size: 14px;
            }
        }
    }
    &__stats {
        margin-top: 70px;
        h4 {
            font-size: 25px;
            font-weight: bold;
            color: $primary;
        }
        h2 {
            font-size: 35px;
        }
        article {
            margin-top: 50px;
            img {
                max-width: 100%;
            }
            figure {
                margin-top: 30px;
                position: relative;
            }
            div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 25px;
            }
            span {
                font-size: 50px;
                display: block;
                font-weight: bold;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: bold;
        }
        p {
            font-size: 14px;
            line-height: 19px;
            margin-top: 30px;
            strong {
                display: block;
                font-size: 18px;
            }
        }
        .primary {
            h3 {
                color: $primary;
            }
            div {
                color: $primary;
            }
        }
        .secondary {
            h3 {
                color: $secondary;
            }
            div {
                color: $secondary;
            }
        }
        .tertiary {
            h3 {
                color: $tertiary;
            }
            div {
                color: $tertiary;
            }
        }
        @include sm-down {
            h4 {
                font-size: 20px;
            }
            h2 {
                font-size: 25px;
                line-height: 25px;
            }
        }
        @include xs-only {
            margin-top: 50px;
            img {
                height: 210px;
            }
            h4 {
                font-size: 16px;
            }
            h2 {
                font-size: 20px;
            }
            article {
                margin-top: 40px;
            }
        }
    }
    &__talents {
        margin-top: 130px;
        h5 {
            font-size: 25px;
            font-weight: bold;
            color: $primary;
        }
        h4 {
            font-size: 35px;
            margin-bottom: 50px;
        }
        p {
            font-size: 16px;
            line-height: 26px;
            margin-top: 25px;
        }
        h2 {
            font-size: 25px;
            line-height: 30px;
            color: $primary;
            font-weight: bold;
            color: $primary;
        }
        h3 {
            font-size: 25px;
            line-height: 30px;
        }
        .button-primary {
            margin-top: 35px;
        }
        .section3 {
            margin-top: -100px;
            .button-primary {
                margin-bottom: 40px;
            }
        }
        @include sm-down {
            h5 {
                font-size: 20px;
            }
            h4 {
                font-size: 25px;
                line-height: 25px;
            }
            h2,
            h3 {
                font-size: 22px;
                line-height: 28px;
            }
            p {
                font-size: 14px;
                line-height: 22px;
                margin-top: 15px;
            }
            .button-primary {
                margin-top: 25px;
            }
            .section3 {
                margin-top: -50px;
            }
            img {
                max-height: 380px;
            }
        }
        @include xs-only {
            margin-top: 70px;
            h5 {
                font-size: 18px;
            }
            h4 {
                font-size: 20px;
            }
            .section3 {
                margin-top: 0;
            }
            img {
                max-height: 280px;
                margin-bottom: 30px;
            }
            h2,
            h3 {
                font-size: 18px;
                line-height: 22px;
            }
            p {
                margin-top: 10px;
            }
            .button-primary {
                margin: 20px 0 0 !important;
            }
        }
    }
    &__good-company {
        text-align: center;
        margin-top: 100px;
        overflow: hidden;
        h4 {
            font-size: 25px;
            font-weight: bold;
            color: $primary;
        }
        h5 {
            font-size: 35px;
            margin-bottom: 50px;
        }
        .logos {
            img {
                opacity: 0.5;
                filter: grayscale(1) brightness(0.7);
            }
        }
        @include sm-down {
            margin-top: 60px;
            h4 {
                font-size: 20px;
            }
            h5 {
                font-size: 25px;
                line-height: 25px;
            }
        }
        @include xs-only {
            h4 {
                font-size: 18px;
            }
            h5 {
                font-size: 20px;
            }
        }
    }
    &__testimonials {
        background: $secondary;
        position: relative;
        margin-top: 250px;
        .top {
            width: calc(100% + 2px);
            position: absolute;
            left: 0;
            bottom: calc(100% - 5px);
        }
        .bottom {
            width: calc(100% + 20px);
            position: absolute;
            top: calc(100% - 5px);
            left: -10px;
        }
        .container {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        h3 {
            font-size: 25px;
            color: white;
        }
        h2 {
            font-size: 35px;
            line-height: 35px;
            color: white;
            margin-top: 10px;
        }
        .desc {
            font-size: 14px;
            line-height: 24px;
            color: white;
            margin-top: 30px;
            img {
                margin-bottom: 4px;
                margin-left: 3px;
            }
        }
        a {
            color: #000;
        }
        .testimonials-slider {
            position: relative;
            margin-top: 80px;
            .slick-list {
                overflow: visible;
                position: relative;
            }
            .slick-track {
                display: flex !important;
            }
            .slick-slide {
                padding: 0 7.5px;
                height: inherit !important;
                & > div,
                article {
                    height: 100%;
                }
                article {
                    transition: all 0.5s;
                }
                &:not(.slick-active) article {
                    opacity: 0.2;
                    pointer-events: none;
                }
            }
            button {
                height: 45px;
                width: 45px;
                border-radius: 100%;
                background: $tertiary;
                box-shadow: 0 0 0 8px rgba($tertiary, 0.2);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                z-index: 100;
                transition: all 0.2s;
                &:hover {
                    box-shadow: 0 0 0 10px rgba($tertiary, 0.2);
                }
                &.prev {
                    right: 100%;
                    margin-right: 25px;
                }
                &.next {
                    left: 100%;
                    margin-left: 25px;
                    padding-left: 3px;
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
            article {
                border-radius: 50px;
                padding: 40px 35px;
                background: #fff;
                ul {
                    li {
                        &:not(:last-child) {
                            margin-right: 3px;
                        }
                    }
                }
                span {
                    font-size: 12px;
                    display: inline-block;
                    margin-top: -3px;
                }
                p {
                    font-size: 14px;
                    line-height: 24px;
                    margin-top: 15px;
                }
                strong {
                    display: block;
                }
            }
        }
        @include lg-down {
            margin-top: 150px;
        }
        @include sm-down {
            h2 {
                font-size: 25px;
                line-height: 25px;
            }
        }
        @include xs-only {
            margin-top: 60px;
            h2 {
                font-size: 20px;
                line-height: 22px;
            }
            .desc {
                margin-top: 20px;
            }
            .button-primary {
                margin-top: 40px;
            }
            .testimonials-slider {
                padding: 0 35px;
                margin-top: 50px;
                button {
                    height: 35px;
                    width: 35px;
                    &.prev {
                        margin-right: -15px;
                    }
                    &.next {
                        margin-left: -15px;
                    }
                }
            }
        }
    }
    &__ready-to-recruit {
        margin-top: 130px;
        .content {
            text-align: center;
            background: $tertiary;
            border-radius: 50px;
            color: white;
            padding: 50px 40px;
            position: relative;
            img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateX(-55%);
            }
        }
        h2,
        h3 {
            color: white;
        }
        h3 {
            margin-bottom: 25px;
        }
        h4 {
            font-size: 25px;
            line-height: 30px;
            font-weight: bold;
            margin-bottom: 25px;
            span {
                color: $primary;
            }
        }
        .newsletter {
            margin-top: 70px;
            input:not([type='submit']) {
                height: 43px;
                border: 1px solid #ebedf2;
                border-right: none;
                border-radius: 40px 0 0 40px;
                padding: 0 25px 1px 20px;
                font-size: 15px;
                width: 100%;
                outline: none;
                transition: all 0.2s;
                &:focus {
                    border-color: $primary;
                }
            }
            .button-primary {
                margin-top: 0;
                border-radius: 0 40px 40px 0;
            }
        }
        .button-primary {
            padding-left: 45px;
            padding-right: 45px;
            margin-top: 20px;
        }
        p {
            margin-top: 120px;
            font-size: 12px;
            line-height: 25px;
        }
        @include lg-down {
            .content {
                img {
                    transform: translateX(-55%) rotate(18deg);
                }
            }
        }
        @include md-down {
            .content {
                img {
                    top: 70%;
                    height: 370px;
                }
            }
        }
        @include sm-down {
            margin-top: 80px;
            .content {
                padding: 40px 30px;
                img {
                    transform: translateX(-55%) rotate(5deg);
                }
            }
            h2 {
                font-size: 18px;
                line-height: 18px;
            }
            h3 {
                font-size: 25px;
                line-height: 25px;
            }
            .newsletter {
                input:not([type='submit']) {
                    height: 36px;
                }
            }
        }
        @include xs-only {
            margin-top: 60px;
            .content {
                padding: 30px 25px;
                img {
                    height: 140px;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            h2 {
                font-size: 16px;
                line-height: 16px;
            }
            h3 {
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 0;
            }
            h4 {
                font-size: 20px;
                line-height: 25px;
            }
            .newsletter {
                margin-top: 160px;
                margin-bottom: 30px;
                input:not([type='submit']) {
                    height: 36px;
                    font-size: 13px;
                    padding-left: 20px;
                }
                .button-primary {
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
        }
    }
}
