@mixin xs-only{
  @media (max-width:575px){
    @content;
  }
}

// SMALL
@mixin sm-only{
  @media (min-width:576px) and (max-width:767px){
    @content;
  }
}

@mixin sm-up{
  @media (min-width:576px){
    @content;
  }
}

@mixin sm-down{
  @media (max-width:767px){
    @content;
  }
}

// MEDIUM
@mixin md-only{
  @media (min-width:768px) and (max-width:991px){
    @content;
  }
}

@mixin md-up{
  @media (min-width:768px){
    @content;
  }
}

@mixin md-down{
  @media (max-width:991px){
    @content;
  }
}

// LARGE
@mixin lg-only{
  @media (min-width:992px) and (max-width:1199px){
    @content;
  }
}

@mixin lg-up{
  @media (min-width:992px){
    @content;
  }
}

@mixin lg-down{
  @media (max-width:1199px){
    @content;
  }
}

// X-LARGE
@mixin xl-only{
  @media (min-width:1200px){
    @content;
  }
}