// Button classes
.button-primary {
    min-height: 43px;
    font-size: 14px;
    background: $primary;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: box-shadow 0.2s;
    color: white !important;
    font-weight: 700;
    border: none;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($primary, 0.1), 0 0 10px rgba($primary, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-white {
    min-height: 43px;
    font-size: 14px;
    background: white;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: box-shadow 0.2s;
    color: $tertiary !important;
    font-weight: 700;
    border: none;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba(white, 0.1), 0 0 10px rgba(white, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-primary-outline {
    min-height: 43px;
    font-size: 14px;
    background: white;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: all 0.2s;
    color: $primary !important;
    font-weight: 700;
    border: 2px solid $primary;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($primary, 0.1), 0 0 10px rgba($primary, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-tertiary {
    min-height: 43px;
    font-size: 14px;
    background: $tertiary;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: box-shadow 0.2s;
    color: white !important;
    font-weight: 700;
    border: none;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($tertiary, 0.1), 0 0 10px rgba($tertiary, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-tertiary-outline {
    min-height: 43px;
    font-size: 14px;
    background: white;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: all 0.2s;
    color: $tertiary !important;
    font-weight: 700;
    border: 2px solid $tertiary;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($tertiary, 0.1), 0 0 10px rgba($tertiary, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-grey-outline {
    min-height: 43px;
    font-size: 14px;
    background: white;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: all 0.2s;
    color: $grey !important;
    font-weight: 700;
    border: 2px solid $grey;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($grey, 0.1), 0 0 10px rgba($grey, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-light-grey {
    min-height: 43px;
    font-size: 14px;
    background: #f8f9fa;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: all 0.2s;
    color: $primary !important;
    font-weight: 700;
    border: none;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba(#f8f9fa, 0.1), 0 0 10px rgba(#f8f9fa, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-job-tag {
    min-height: 15px;
    font-size: 14px;
    background: #354b7b;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    transition: box-shadow 0.2s;
    color: white !important;
    font-weight: 500;
    border: none;
    &.small {
        font-size: 12px;
    }
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($tertiary, 0.1), 0 0 10px rgba($tertiary, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 14px;
        font-size: 13px;
        padding: 2px 10px;
    }
}

.button-google {
    margin-bottom: 20px;

    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($facebook, 0.1), 0 0 10px rgba($facebook, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        // padding: 0 18px;
    }
}

.button-facebook {
    min-height: 39px;
    font-size: 14px;
    background: $facebook;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: box-shadow 0.2s;
    color: white !important;
    font-weight: 700;
    border: none;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($facebook, 0.1), 0 0 10px rgba($facebook, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 36px;
        font-size: 13px;
        padding: 0 18px;
    }
}

.button-linkedin {
    min-height: 39px;
    font-size: 14px;
    background: $linkedin;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    transition: box-shadow 0.2s;
    color: white !important;
    font-weight: 700;
    border: 1px solid #2a4754;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 8px rgba($linkedin, 0.1), 0 0 10px rgba($linkedin, 0.3);
        &.no-shadow-drop {
            box-shadow: 0 0 10px 100px rgba(white, 0.1) inset;
        }
    }

    @include sm-down {
        min-height: 38px;
        font-size: 13px;
        // padding: 0 18px;
    }
}

[class*='button'][disabled] {
    background: #bbb;
    pointer-events: none;
}

.dropdown {
    .dropdown-menu {
        padding: 7px 0;
        border: 0;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(black, 0.1);
        top: 100% !important;
        display: block !important;
        transform: translateY(-10px) scale(0.95);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: all 0.2s;
        &.show {
            top: calc(100% + 7px) !important;
            transform: translateY(0) scale(1) !important;
            opacity: 1;
            pointer-events: initial;
            visibility: visible;
        }
        a {
            padding: 8px 15px;
            font-size: 14px;
            transition: all 0.2s;
            &:hover {
                background: rgba($primary, 0.05);
            }
        }
    }
}
